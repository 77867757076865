import React from 'react';

import SEO from '../components/SEO';
import PageNetworkMapLayout from '../components/NewNetwork/PageNetworkMapLayout';

const NetworkSearch = () => {
  return (
    <>
      <SEO title="Network — Search"></SEO>
      <PageNetworkMapLayout pageType="search"></PageNetworkMapLayout>
    </>
  );
};

export default NetworkSearch;
